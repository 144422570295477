<template>
  <div>
    <div class="loading" v-if="isLoading == true">
        <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
        </div>
    </div>
    <b-form v-on:submit.prevent="generate">
      <b-row>
        <b-col>
          <b-form-file v-model="file" ref="file-input" class="mb-2" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @change="checkExtension"></b-form-file>
          <p class="mt-0">Selected file: <b>{{ file ? file.name : '' }}</b></p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button type="submit" variant="success" class="m-1" >Generate Bulk Shipping</b-button>
          <b-button type="button" @click="file = ''" variant="danger" class="m-1" >Reset</b-button>
          <b-button type="button" @click="download" variant="primary" class="m-1" >Download File Template</b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-row class="mt-3">
      <b-col>
        <h5>Peringatan !</h5>
        <ul style="font-size: 10pt; font-weight: bold">
          <li>Sebelum melakukan prosess generate harap cek koneksi untuk meminimalisir koneksi yang terputus.</li>
          <li>Pengisian format data harus sama persis seperti template yang disediakan.</li>
          <li>Nama Kota/Kabupaten ini berupa casesensitive. contoh penulisan kota/kabupaten yang sesuai: Kota Jakarta Selatan.</li>
          <li>Nama Kecamatan ini berupa casesensitive.</li>
          <li>Nama Kelurahan ini berupa casesensitive.</li>
          <li>Jika Nama Kota/Kabupaten, Kecamatan dan kelurahan tidak ditemukan harap menghubungi pihak terkait.</li>
          <li>Jumlah Maksimal baris yang di generate adalah 500 baris.</li>
          <li>Proses Generate Akan berhenti jika ada baris yang bermasalah. Namun data sebelum baris yang bermasalah akan berhasil di generate.</li>
          <li>Beberapa Nama Colom yang dapat diubah.
            <ul>
              <li>Origin</li>
              <li>Shipping Type</li>
              <li>City</li>
              <li>District</li>
              <li>Village</li>
              <li>Postal Code</li>
              <li>Shipping Fee</li>
            </ul>
          </li>
        </ul>
      </b-col>
    </b-row>
    <b-row class="mb-3" v-if="$can('export')">
      <b-col sm>
        <div>
          <router-link :to="{name: 'Add Shipping Cost RPX'}" class="btn btn-primary"><i class="fa fa-plus"></i> Create Shipping</router-link>&nbsp;
          <button class="btn btn-success" type="button" @click="exportTable">Export</button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Origin, Kota, Kecamatan, Kelurahan, Kode Pos"
          >
            <b-input-group
            >
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Origin, Kota, Kecamatan, Kelurahan, Kode Pos ..."
                @keypress="doFilter"
                @keyup.delete="doFilter"
              >
              </b-form-input>
              <b-input-group-append
              >
                <b-button
                  variant="secondary" @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="origin-slot" slot-scope="prop">
        <span>{{ prop.rowData.origin }}</span>
      </template>
      <template slot="shipping_type-slot" slot-scope="prop">
        <span>{{ prop.rowData.shipping_type }}</span>
      </template>
      <template slot="city-slot" slot-scope="prop">
        <span>{{ prop.rowData.city }}</span>
      </template>
      <template slot="district-slot" slot-scope="prop">
        <span>{{ prop.rowData.district }}</span>
      </template>
      <template slot="village-slot" slot-scope="prop">
        <span>{{ prop.rowData.village }}</span>
      </template>
      <template slot="postal_code-slot" slot-scope="prop">
        <span>{{ prop.rowData.postal_code }}</span>
      </template>
      <template slot="fee-slot" slot-scope="prop">
        <span>{{ prop.rowData.fee }}</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions">
              <button class="btn btn-info" @click="onAction('edit-item', prop.rowData)">Edit</button>&nbsp;
              <button class="btn btn-danger" @click="onAction('delete-item', prop.rowData)"><i class="fa fa-trash-o"></i></button>
          </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import accounting from 'accounting'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        file: '',
        isLoading: false,
        filterText: '',
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `shipping-cost?method=RPX`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'postal_code',
            sortField: 'minting_partner_shipping_fee.postal_code',
            direction: 'asc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'origin-slot',
            sortField: 'minting_partner_shipping_fee.origin',
            title: 'Origin'
          },
          {
            name: 'shipping_type-slot',
            sortField: 'minting_partner_shipping_fee.shipping_type',
            title: 'Tipe Pengiriman'
          },
          {
            name: 'city-slot',
            sortField: 'minting_partner_shipping_fee.city_id',
            title: 'Kota'
          },
          {
            name: 'district-slot',
            sortField: 'minting_partner_shipping_fee.district_id',
            title: 'Kecamatan'
          },
          {
            name: 'village-slot',
            sortField: 'indoregion_villages.name',
            title: 'Kelurahan'
          },
          {
            name: 'postal_code-slot',
            sortField: 'minting_partner_shipping_fee.postal_code',
            title: 'Kode Pos'
          },
          {
            name: 'fee',
            sortField: 'minting_partner_shipping_fee.fee',
            title: 'Shipping Fee',
            titleClass: 'center aligned',
            dataClass: 'right aligned'
          },
          {
              name: 'actions-slot',
              title: 'Actions',
              titleClass: 'center aligned',
              dataClass: 'center aligned',
              width: '20%',
          }
        ]
      }
    },
    methods: {
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      exportTable() {
        this.isLoading = true
        this.$http.get(`shipping-cost-export?method=RPX`)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = '';// clear the text in text input
        this.$events.$emit('date-filter-reset')
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      formatNumber (value) {
        return accounting.formatNumber(value, 2)
      },
      onAction (action, data) {
          if (action == 'edit-item') {
              this.$router.push({name: 'Edit Shipping Cost RPX', params: { id: data.id }})
          }else{
              this.$swal.fire({
                  title: 'Are you sure?',
                  text: 'You will not be able to recover this Shipping!',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, keep it'
              }).then((result) => {
                  if (result.value) {

                      this.$http.delete(`shipping-cost/` + data.id)
                      .then(() => {
                      this.$swal.fire(
                          'Deleted!',
                          'Shippping has been deleted.',
                          'success'
                      ).then(() => {
                          location.reload();
                      })
                      }).catch((error) => {
                          if (error.response) {
                              this.errors.code = error.response.status;
                              this.errors.message = error.response.data.meta.message;
                              this.errors.status = error.response.data.meta.code;
                          }
                      })
                  } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                      this.$swal.fire(
                          'Cancelled',
                          'Shipping was successfully canceled for deletion!',
                          'error'
                      )
                  }
              })
          }
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      checkExtension (sender) {
        var validExts = new Array(".xlsx", ".xls");
        var fileExt = sender.target.value;
        fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
        if (validExts.indexOf(fileExt) < 0) {
          this.$swal.fire(
            'Failed!',
            "Invalid file selected, valid files are of " + validExts.toString() + " types.",
            'error'
          ).then(() => {
            this.file = ''
            return false;
          })
        }
        else return true;
      },
      generate() {
        if (this.file === '') {
          return this.$swal.fire(
            'Failed',
            'Harap masukan file untuk diimport.',
            'error'
          )
        }
        const formData = new FormData
        formData.append('file', this.file, this.file.name)
        formData.append('method', 'RPX')
          this.$swal.fire({
            title: 'Melakukan Proses Generate Shipping',
            text: 'Mohon untuk tidak memuat ulang halaman ini!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Kembali'
          }).then((result) => {
            this.isLoading = true
            if (result.value) {
              this.$http.post(`shipping-cost`, formData)
              .then(() => {
                this.isLoading  = false
                this.$swal.fire(
                  'Success',
                  'Shipping Berhasil digenerate',
                  'success'
                ).then(() => {
                  location.reload()
                })
              }).catch((error) => {
                if (error.response) {
                  this.isLoading = false
                  if(error.response.data.meta.code == 400){
                    this.$swal.fire(
                      'Failed!',
                      error.response.data.meta.message,
                      'error'
                    ).then(() => {
                      location.reload()
                    })
                  }else if(error.response.data.meta.code == 422){
                    this.$swal.fire(
                      'Failed!',
                      error.response.data.meta.message,
                      'error'
                    ).then(() => {
                      location.reload()
                    })
                  }
                }
              })
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.isLoading = false
              this.$swal.fire(
                'Dibatalkan',
                'Proses Generate Berhasil dibatalkan!',
                'error'
              )
            }
          })
      },
      download() {
        location.href = 'https://content.treasury.id/example/shipping-example.xlsx'
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
